$primary-color: rgba(59, 50, 118, 1);
$primary-color-opacity-min: rgba(59, 50, 118, 0.4);
$secondary-color: rgba(246, 236, 73, 1);
$secondary-color-opacity-min: rgba(246, 236, 73, 0.6);
$tertiary-color: white;
$primary-font-family: Avenir;
$breakpoint-tablet: 800px;

#login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    border-radius: 20px;
    background-color: white;
    padding: 30px;
    box-shadow: 12px 12px 2px 1px #3b3276;

    #login-input {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    #login-button {
      @media (max-width: $breakpoint-tablet) {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
